import React, {useEffect} from "react";
import styled from "styled-components";
import {
  OpensansBoldMineShaft14px,
  OpensansBoldMidGray14px,
  OpensansNormalMineShaft14px,
  OpensansBoldMadison14px,
} from "../../styledMixins";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import './ResultsTable.css';
import _ from "lodash";

function ResultsTable(props) {

  const { results } = props;

  
  function createData(id, sku, compProdName, tfxProdName, tfxProdNum, compDesc) {
    return {id, sku, compProdName, tfxProdName, tfxProdNum, compDesc };
  }

  const [rows, setRows] = React.useState([]);
  
  useEffect(()=>{

    let tableData = [];
    let prevSeen = ""
    let i = 0;
    _.forEach(results, function (result){
      _.forEach(result.tfxList, function(tfxResult){
        if(result["manufacturingNumber"] == prevSeen){
          let newKey = result["manufacturingNumber"] + i;
          i++;
          tableData.push(createData(newKey,"", "",
        tfxResult["tfxManufacturingDescription"], tfxResult["teleflexCross"], ""));
        }else{
          i = 0;
          prevSeen = result["manufacturingNumber"];
        tableData.push(createData(result["manufacturingNumber"], result["manufacturingNumber"], result["vendorName"],
        tfxResult["tfxManufacturingDescription"], tfxResult["teleflexCross"], result["manufacturingDescription"]));
        }
      });

    });
    setRows(tableData);
    
  }, [results]);
  

  return (
      <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="results-table-header">
              <TableCell>SKU</TableCell>
              <TableCell >Product Description</TableCell>
              <TableCell sx={{ backgroundColor: '#02a8ce1a', width: '30%' }} >Teleflex Product</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              if(row.compProdName.length > 1){
                return(
                  <TableRow key={row.id} className="results-table-rows-leader">
                    <TableCell sx={{ width: '30%' , borderBottomColor: 'transparent', borderTop:'1px solid #e5e5e5' }} >{row.sku}</TableCell>
                    <TableCell sx={{borderBottomColor:'transparent'}}>
                      <h3>{row.compProdName}</h3>
                      {row.compDesc.toUpperCase()}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#02a8ce1a', }}>
                      <h3>
                        <a href={`https://www.teleflexvascular.com/products/${row.tfxProdNum.toLowerCase()}`} target="_blank">{row.tfxProdName} ›</a>
                      </h3>
                      {row.tfxProdNum}
                    </TableCell>
                  </TableRow>
                )
              }
              else{
                return(
                  <TableRow key={row.id} className="results-table-rows">
                    <TableCell sx={{ width: '30%', borderBottomColor: 'transparent'}} >{row.sku}</TableCell>
                    <TableCell sx={{borderBottomColor: 'transparent'}}>
                      <h3>{row.compProdName}</h3>
                      {row.compDesc.toUpperCase()}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#02a8ce1a' }}>
                      <h3>
                        <a href={`https://www.teleflexvascular.com/products/${row.tfxProdNum.toLowerCase()}`} target="_blank">{row.tfxProdName} ›</a>
                      </h3>
                      {row.tfxProdNum}
                    </TableCell>
                  </TableRow>
                )
              }
})}
          </TableBody>
        </Table>
      </TableContainer>
  );
}


export default ResultsTable;
