import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {debounce} from 'lodash';
import {InputLabel} from "@mui/material";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '11px 0',
    },
    textField: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%",
        backgroundColor: '#FFFFFF',
    },
    resize:{
        fontSize: 14
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    label: {
        padding: "1px",
        fontSize: '2rem !important',
        marginLeft: theme.spacing(1),
        marginBottom: -8,
    }
}));

export default function FrenchGaugeForm() {
    const classes = useStyles();

    const [frenchValue, setFrenchValue] = useState(10.2);
    const [gaugeValue, setGaugeValue] = useState(10);

    const debounceFrenchChange = debounce((value) => {
            setGaugeValue(convertFrenchToGauge(value));
        }, 1000);
    
    const debounceGaugeChange = debounce((value) => {
            setFrenchValue(convertGaugeToFrench(value));
        }, 1000);
    
    const handleFrenchChange = (event) => {
        const value = event.target.value;
        // if (value === '' || (value >= 0 && value <= 10)) {
            setFrenchValue(value);
            debounceFrenchChange(value);
        // }
    };

    const handleGaugeChange = (event) => {
        const value = event.target.value;
        // if (value === '' || (value >= 10 && value <= 33)) {
            setGaugeValue(value);
            debounceGaugeChange(value);
        // }
    };

    const gaugeTable = [3.404, 3.048, 2.769, 2.413, 2.108, 1.829, 1.651, 1.473, 1.270, 1.067, 0.902, 0.813, 0.711, 0.635, 0.559, 0.508, 0.457, 0.406, 0.356, 0.333, 0.305, 0.254, 0.229, 0.203];

    const convertFrenchToGauge = (frenchValue) => {
        // Add your conversion logic here
        // For example, you can use a formula to convert from French to gauge
        if(!frenchValue)
            return; 
        
        if (frenchValue < 0 || isNaN(frenchValue)) {
            // alert("French size invalid");
            return "French size input out of range";
        }

        let sizeMM         = frenchValue / 3;
        let remainder      = 0;
        let testGauge      = 0;
        let rightSizeIndex = 0;
        let rightSizeMM    = 0;
        let leftSizeMM     = 0;
        
        if (sizeMM > 3.404) {
            return "<10";
        }

        for (let i = 0; i < 24; i++) {
            testGauge  = gaugeTable[i];
            if (sizeMM > testGauge) {
                rightSizeIndex = i;
                rightSizeMM = testGauge;
                leftSizeMM = gaugeTable[i - 1];

                // match found
                let rightGauge = rightSizeIndex + 10;
                // interpolate the Gauge from the size in mm
                let intervMM = leftSizeMM - rightSizeMM;
                remainder = sizeMM - rightSizeMM;
                let gauge = rightGauge - remainder / intervMM;
                gauge = Math.round(gauge * 10) / 10;
                return gauge;
            }
        }
        
        // no match in aGaugeTable
        // alert("French size input out of range");
        
        // return (frenchValue * 0.127) + 1;
    };

    const convertGaugeToFrench = (gaugeValue) => {
        
        if(!gaugeValue)
            return;

        // Add your conversion logic here
        // For example, you can use a formula to convert from gauge to French
        let gaugeIndex  = Math.round(gaugeValue) - 10;
        if ((gaugeIndex < 0) || (gaugeIndex > 23) || isNaN(gaugeIndex)) {
            // alert("Gauge out of range");
            return "Gauge size input out of range";
        }

        // Get mm from the Gauge table
        let sizeMM = parseFloat(gaugeTable.slice(gaugeIndex, gaugeIndex + 1));
        return Math.round(sizeMM * 30) / 10;
        // return (gaugeValue - 1) / 0.127;
    };

    return (
        <form className={classes.container} noValidate autoComplete="off">
            <Grid container spacing={2}>
                <Grid item xs={12} s={12} md={12} lg={12}>
                    <TextField
                        onFocus={event => {
                            event.target.select();
                        }}
                        id="standard-gauge"
                        type="text"
                        name="Gauge"
                        label={"Gauge"}
                        className="gauge-text-field"
                        InputProps={{
                            classes: {
                                input: classes.resize,
                            },
                        }}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={gaugeValue}
                        onChange={handleGaugeChange}
                    />
                </Grid>

                <Grid item xs={12} s={12} md={12} lg={12}>
                    <TextField
                        onFocus={event => {
                            event.target.select();
                        }}
                        id="standard-french"
                        type="text"
                        name="French"
                        label={"French"}
                        className="french-text-field"
                        InputProps={{
                            classes: {
                                input: classes.resize,
                            },
                        }}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={frenchValue}
                        onChange={handleFrenchChange}
                    />
                </Grid>
            </Grid>
        </form>
    );
}