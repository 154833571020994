import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { OpensansNormalWhite18px } from "../../styledMixins";


function EmailModalCTA(props) {

    const { handleSendEmail } = props;

    return (
    <a style={{display: 'inline-flex'}} onClick={handleSendEmail}>
      <ProductsCTA>
        <ViewProducts>Send</ViewProducts>
      </ProductsCTA>
    </a>
  );
}

const ProductsCTA = styled.div`
  height: 48px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(180deg, #02a8ce 0%, #0086a4 100%);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  width: fit-content;
  text-align: center;
  &:hover {
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  }
`;

const ViewProducts = styled.div`
  ${OpensansNormalWhite18px}
  min-height: 28px;
  min-width: 120px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;





export default EmailModalCTA;
