import React from "react";
import styled from "styled-components";
import '../tfxStyles.css';
import '../bootstrap.min.css';
import '../font-awesome.css';
import Grid from "@mui/material/Grid";

function PrimaryHeader(props) {
  const { src } = props;

  return (
      <div id="header" style={{maxWidth: '100%'}}>
          <div className="header">
              <div className="navbar navbar-default" role="navigation">
                  <div className="container" style={{maxWidth: '100%'}}>
                      <div className="navbar-header" style={{ marginTop: '25px'}}>
                          <Grid container spacing={2}>
                              <Grid item xs={6} sm={4} md={4}>
                                  <a className="navbar-brand logo" href="https://www.teleflex.com">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                                           viewBox="37.5 251.7 537 80.6">
                                          <g fill="#00205B">
                                              <path
                                                  d="M561.9 298.7c6.9 0 12.6-5.6 12.6-12.6s-5.7-12.6-12.6-12.6c-7 0-12.6 5.6-12.6 12.6 0 6.9 5.6 12.6 12.6 12.6zm0-2.9c-5.4 0-9.7-4.3-9.7-9.7s4.3-9.7 9.7-9.7c5.3 0 9.7 4.3 9.7 9.7.1 5.3-4.3 9.7-9.7 9.7zm3-8.6c2.4-.2 3.7-1.2 3.7-3.7 0-3.6-2.2-4.2-5.3-4.2h-6.8V293h2.9v-5.6h2.5l3 5.6h3.198l-3.198-5.8zm-5.5-2v-3.7h3.3c1.3 0 3 0 3 1.6 0 1.7-.8 2.1-2.4 2.1h-3.9zm-194.1-21.9v-11.5h-30.4c-9.4 0-16 5.5-16 15.8v64h22.2v-47.1h18.2V273h-18.2v-5.4c0-3.2 1.4-4.4 4.7-4.4h19.5v.1zm89.5 34.4v-7.1c0-3.4-2.1-5.6-5.5-5.6h-19.6c-3.4 0-5.5 2.2-5.5 5.6v7.1h30.6zm17.7 11.6h-48.3v5.5c0 3.9 3.1 6 7.1 6h40.9v4c0 4.7-4.4 7.5-11.7 7.5h-40.4c-12.2 0-18.3-5.4-18.3-17.5v-24.5c0-10.9 6.1-16.7 18.3-16.7h36c12.2 0 16.3 5.9 16.3 16.7v19h.1zm-79 22.3v-79.8h-22.3v79.8M293 297.5v-7.1c0-3.4-2.1-5.6-5.5-5.6h-19.6c-3.4 0-5.5 2.2-5.5 5.6v7.1H293zm17.7 11.8h-48.3v5.5c0 3.9 3.1 6 7.1 6h40.9v4c0 4.7-4.4 7.5-11.7 7.5h-40.4c-12.2 0-18.3-5.4-18.3-17.5v-24.5c0-10.9 6.1-16.7 18.3-16.7h36c12.2 0 16.3 5.9 16.3 16.7v19h.1zm-79.3 22.3v-79.8h-22.2v79.8m-26.6-33.8v-7.1c0-3.4-2.1-5.6-5.5-5.6h-19.6c-3.4 0-5.5 2.2-5.5 5.6v7.1h30.6zm17.9 11.5h-48.3v5.5c0 3.9 3.1 6 7.1 6h40.9v4c0 4.7-4.4 7.5-11.7 7.5h-40.4c-12.2 0-18.3-5.4-18.3-17.5v-24.5c0-10.9 6.1-16.7 18.3-16.7h36c12.2 0 16.3 5.9 16.3 16.7v19h.1z"></path>
                                              <path
                                                  d="M504.9 274.4l12.9 12.8 12.6-12.8h19.5l-22.7 22.8 34.2 34.4h-32.1l-18-18.2-18.1 18.2h-19l28.3-28.3-29.2-28.9m-317.9-11.2v-11.5h-39.7c-9.4 0-16 5.5-16 15.8v64H120v-63.7c0-3.2 1.7-4.7 5.1-4.7h30.3v.1zm-117.9 0v-11.5h39.7c9.4 0 16 5.5 16 15.8v64H72.8v-63.7c0-3.2-1.7-4.7-5.1-4.7H37.5v.1z"></path>
                                          </g>
                                          <image src="/application/themes/teleflex/assets/img/tfx_logo.png" alt="">
                                          </image>
                                      </svg>
                                  </a>
                              </Grid>
                              <Grid item xs={12} sm={12} md={4}>
                                  <span className="navbar-brand navbar-tagline" style={{color:'#00205B', top:'0px', margin:'auto', fontSize: '16px'}}>A Global Provider of Medical Technologies</span>
                              </Grid>
                          </Grid>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}


export default PrimaryHeader;
