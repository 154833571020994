import React, {useEffect} from "react";
import PrimaryCTA from "../PrimaryCTA";
import QuestionsSidebar from "../QuestionsSidebar";
import styled from "styled-components";
import {
  OpensansNormalMineShaft18px,
  ArialmtNormalCerulean13px,
  OpensansBoldMidGray24px,
  Border1pxBonJour,
} from "../../styledMixins";
import "./LandingPage.css";
import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  Modal,
  TextField, ToggleButton, ToggleButtonGroup,
  Typography
} from "@mui/material";
import _ from "lodash";
import ResultsOutput from "../ResultsOutput";
import Grid from "@mui/material/Grid";
import EmailModalCTA from "../EmailModalCTA";
import {alpha, styled as styled2} from "@mui/material/styles";
import authService from "../api-authorization/AuthorizeService";
import {landingPageData, results2Data} from "../../App";
import * as converter from "json-2-csv";
import PrimaryHeader from "../PrimaryHeader";
import SKUHero from "../SKUHero";
import PrimaryFooter from "../PrimaryFooter";
import FrenchGauge from "../FrenchGauge";
import {Close} from "@mui/icons-material";

function LandingPage(props) {
  const {
    searchForAProduct,
    startTypingOrSele,
    questionsSidebarProps,
    frenchGaugeSidebar,
      resultsOutputProps
  } = {...landingPageData,...results2Data};

  const [results, setResults] = React.useState();
  const [compSkus, setCompSkus] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [emails, setEmails] = React.useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  useEffect(() => {

    fetch('SkuEntry', {
      method: 'GET'
    }).then(response => response.json())
        .then(data => {
      let skuEntries = data;
      let skus = [];

      // console.log("Get SKUs response:", skuEntries);
      for(let i in skuEntries){
        skus.push(skuEntries[i])
      }

      setCompSkus(skus);

    }).catch((ex) => {
      console.log(ex);
    });

  },[]);

  function handleLookup(e) {
    if(selectedOptions.length >0){
    console.log("selectedOptions:", selectedOptions);
    let formattedOptions = [];
    _.forEach(selectedOptions, function (option){
      if(option["title"]){
        formattedOptions.push(option["title"]);
      }else{
        formattedOptions.push(option);
      }
    });
    console.log("formattedOptions:", formattedOptions);
    fetch('getspecific?skuentry=' + formattedOptions.toString(), {
      method: 'GET',
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(response => response.json())
        .then(rtn => {
          
          setResults(rtn);

          document.getElementById("results").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            
        }).catch((ex) => {
    });
  }
  }
  
  function handleChange(event, value){
    //TODO: Add check for comma separated
    if(value[0]?.includes(",") || value[0]?.includes(" ")) {
      let values = value[0].split(/[ ,]+/);
      const newOpts = values.map((tag) => tag.trim());
      setSelectedOptions(newOpts);
    }else{
      setSelectedOptions(value);
    }
  }

  function handleEmailChange(event, value){
    setEmails(value);
  }
  
  function handleSendEmail(e){
    converter.json2csv(results, (err, csv) => {
      if (err) {
        throw err;
      }
      const formData = new FormData();
      formData.append('emails', emails.toString());
      formData.append('file', selectedOptions.toString());
      formData.append('type', fileType);

      fetch('email', {
        method: 'POST',
        body: formData,
      }).then(response => {
        console.log("importData response:", response);
      }).catch((ex) => {
        console.log(ex);
      });

      handleClose();
    });
    
  }

  const [fileType, setFileType] = React.useState('csv');

  const handleFileTypeChange = (event) => {
    const value = event.target.value;
    setFileType(value);
  };

  return (
        <div className="container-center-horizontal">
          <div className="landingpage screen">
            <Grid container >
              <Grid item xs={12}>
                <PrimaryHeader/>
              </Grid>
              <Grid item xs={12}>
                <SKUHero {...sKUHeroData}/>
              </Grid>
            </Grid>
            <FlexRow>
              <Grid container spacing={2} id={"search-form"}>
                <Grid item xs={12} s={12} md={12} lg={8}>
                  <FlexCol1>
                    <Group show-on-scroll>
                      <Group17 src="../static/img/landing-page-group-17-2A4F0B27-D353-4F52-A092-6D7FA6AC98E6@2x.svg"/>
                      <FlexCol2>
                        <SearchForAProduct>{searchForAProduct}</SearchForAProduct>
                        <StartTypingOrSele>{startTypingOrSele}</StartTypingOrSele>
                      </FlexCol2>
                    </Group>

                    <SearchForm>
                      <Autocomplete
                          ListboxProps={{
                            sx: {fontSize: 14},
                          }}
                          sx={{
                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                              fontSize: 14,
                            },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#00A9CE"
                              }
                            }
                          }}
                          freeSolo
                          multiple
                          autoSelect={true}
                          id="tags-standard"
                          className="current-sku-dropdown"
                          options={compSkus}
                          getOptionLabel={option => option.title || option}
                          onChange={handleChange}
                          value={selectedOptions}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Current SKU(s)*"
                                  label={"Current SKU(s)*"}
                                  className="current-sku-text-field"
                                  sx={{fontSize: '14px'}}
                              />
                          )}
                      />
                      <PrimaryCTA handleLookup={handleLookup}/>
                    </SearchForm>

                    

                  </FlexCol1>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <StackedGroup show-on-scroll>
                    <QuestionsSidebar
                        questions={questionsSidebarProps.questions}
                        ifYouCanTFindA={questionsSidebarProps.ifYouCanTFindA}
                        className={questionsSidebarProps.className}
                    />
                  </StackedGroup>
                </Grid>
              </Grid>
            </FlexRow>
            {results &&
            <FlexRow>
                  <Grid container spacing={2}>
                    <Grid item xs={12} s={12} md={12} lg={12}>
                      <ResultsOutput
                          yourResults={resultsOutputProps.yourResults}
                          iconMail={resultsOutputProps.iconMail}
                          emailResults={resultsOutputProps.emailResults}
                          resultsTableProps={resultsOutputProps.resultsTableProps}
                          results={results}
                          handleOpen={handleOpen}
                      />
                    </Grid>

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                      <Box sx={modalStyle}>
                        <Typography
                            id="modal-modal-title"
                            variant="h5"
                            component="h2"
                            sx={{
                              color: '#585F69',
                              fontFamily: "Open Sans",
                              fontSize: '24px',
                              fontWeight: 'bold',
                              letterSpacing: '0',
                              lineHeight: '33p'
                            }}>
                          Email Your Results
                          <IconButton style={{marginLeft:80}} onClick={handleClose}>
                            
                            <Close/>
                          </IconButton>
                        </Typography>

                        <Grid container spacing={2} sx={{mt: 4, paddingLeft: '16px'}}>
                          <FormControl variant="standard" sx={{width: '100%'}}>

                            <Grid item xs={12}>

                              <Autocomplete
                                  onKeyDown={(event) =>{
                                    if(event.key === ',' || event.key ===' '|| event.key === 'Spacebar'){
                                      event.key = 'Enter'
                                    }
                                  }
                              }
                                  componentsProps={{ popper: { style: { width: '0px' } } }}
                                  ListboxProps={{
                                    sx: {fontSize: 14},
                                  }}
                                  sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                      fontSize: 14,
                                    },
                                  }}
                                  freeSolo
                                  multiple
                                  autoSelect={true}
                                  id="email-multi-input"
                                  className="current-sku-dropdown"
                                  options={[""]}
                                  getOptionLabel={option => option.title || option}
                                  onChange={handleEmailChange}
                                  renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          variant="outlined"
                                          placeholder="youremail@example.com"
                                          label={"Email Address"}
                                          className="current-sku-text-field"
                                          sx={{fontSize: '14px'}}
                                          required
                                      />
                                  )}
                              />

                            </Grid>
                            <Grid item xs={12}>
                              <EmailModalCTA handleSendEmail={handleSendEmail}/>
                              <ToggleButtonGroup
                                  color="primary"
                                  value={fileType}
                                  exclusive
                                  onChange={handleFileTypeChange}
                                  aria-label="CSV/Excel Toggle"
                                  size="large"
                                  sx={{verticalAlign: 'bottom', ml: 2}}
                              >
                                <ToggleButton value="csv">CSV</ToggleButton>
                                <ToggleButton value="excel">Excel</ToggleButton>
                              </ToggleButtonGroup>
                            </Grid>

                          </FormControl>
                        </Grid>

                      </Box>
                    </Modal>
                  {/*</FlexCol1>*/}
                  </Grid>
            </FlexRow>
            }
            <FlexRowGauge>
              <Grid container spacing={2}>
                <Grid item xs={12} s={8} md={8} lg={8}>
                  <StackedGroupFrenchGauge show-on-scroll>
                    <FrenchGauge
                        frenchGaugeTitle={frenchGaugeSidebar.frenchGaugeTitle}
                        instruct={frenchGaugeSidebar.instruct}
                    />
                  </StackedGroupFrenchGauge>
                </Grid>
              </Grid>
            </FlexRowGauge>
          </div>
        </div>
);
}

const FlexRow = styled.div`
  height: auto;
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  max-width: 1264px;
  width: 100%;
`;

const FlexRowGauge = styled.div`
  height: auto;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  max-width: 1264px;
  width: 100%;
  
   @media only screen and (max-width: 1200px) {
    margin-top: -20px;
    padding-left: 10px;
  }
`;

const FlexCol1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // min-height: 240px;
  
  @media only screen and (max-width: 600px) {
    padding-left: 15px
  }
`;

const Group = styled.div`
  height: 68px;
  display: flex;
  align-items: center;
  // min-width: 701px;
`;

const Group17 = styled.img`
  width: 62px;
  height: 60px;
  margin-left: -1px;
  margin-top: 4px;
`;

const FlexCol2 = styled.div`
  width: 621px;
  margin-left: 19px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 68px;
  word-break: break-word;
  width: 100%;
`;

const SearchForAProduct = styled.div`
  ${OpensansBoldMidGray24px}
  min-height: 28px;
  font-weight: 700;
  letter-spacing: 0;
  
  @media only screen and (max-width: 600px) {
    font-size: 22px
  }
`;

const StartTypingOrSele = styled.div`
  ${OpensansNormalMineShaft18px}
  min-height: 28px;
  margin-top: 12px;
  letter-spacing: 0;
  line-height: 28px;
  
  @media only screen and (max-width: 600px) {
    font-size: 17px
  }
`;

const SearchForm = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 132px;
  
  
  
  @media only screen and (max-width: 600px) {
    margin-top: 80px;
  }
`;

const OverlapGroup1 = styled.div`
  width: 855px;
  height: 44px;
  position: relative;
`;

const InactiveDropdown = styled.div`
  ${Border1pxBonJour}
  position: absolute;
  width: 855px;
  height: 44px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  background-color: var(--white);
`;

const Rectangle = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 2px;
  margin-right: 8px;
  background-color: var(--bon-jour);
`;

const Icon = styled.img`
  margin-top: 1px;
  height: 9px;
  width: 16px;
  align-self: center;
  margin-right: 19px;
`;

const CurrentSKUs = styled.div`
  position: absolute;
  top: 11px;
  left: 18px;
  opacity: 0.4;
  font-family: var(--font-family-open_sans);
  color: #010304;
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21px;
`;

const StackedGroup = styled.div`
  max-width: 307px;
  height: 236px;
  position: relative;
  margin-left: 102px;
  display: flex;
  @media (max-width: 1200px) {
   margin-left: 0px;
  };
  
  @media only screen and (max-width: 600px) {
    padding-left: 15px;
    max-width: 100%;
  };
`;

const StackedGroupFrenchGauge = styled.div`
  position: relative;
  display: flex;
  margin-top: 16px;
  @media (max-width: 1200px) {
   margin-left: 0px;
  }
`;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '0px !important',
  p: 4,
};

const BootstrapInput = styled2(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const sKUHeroData = {
  gettyimages1321691592170667A: "/static/img/dropdown-gettyimages-1321691592-170667a-51A34ABE-7506-4E46-A450-4A19C6F218AD@2x.png",
  teleflexSkuCrossR: "Teleflex SKU Cross Reference Tool",
  enterOrSearchFor: "Enter or search for your current product’s SKU number to find the Teleflex product that best fits your needs.",
};

const FlexCol = styled.div`
  max-width: 1228px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;


export default LandingPage;
