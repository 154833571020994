import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { OpensansNormalWhite18px } from "../../styledMixins";


function PrimaryCTA(props) {
    
  const { handleLookup } = props;
  
  return (
    <a onClick={handleLookup}>
      <ProductsCTA>
        <ViewProducts>View Products</ViewProducts>
      </ProductsCTA>
    </a>
  );
}

const ProductsCTA = styled.div`
  height: 48px;
  margin-top: 40px;
  display: flex;
  padding: 0 28px;
  justify-content: flex-end;
  align-items: center;
  min-width: 178px;
  background: linear-gradient(180deg, #02a8ce 0%, #0086a4 100%);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  }
`;

const ViewProducts = styled.div`
  ${OpensansNormalWhite18px}
  min-height: 28px;
  min-width: 120px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

export default PrimaryCTA;
