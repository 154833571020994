import React from "react";
import styled from "styled-components";
import { OpensansBoldCerulean18px } from "../../styledMixins";


function Group3() {
  return (
      <a href="mailto:vss@teleflex.com">
        <Group>
          <ContactUs>Contact Us</ContactUs>
          <OverlapGroup>
            <Rectangle src="../static/img/landing-page-rectangle-450ADF8B-045B-4098-B128-024F8B948F2E@2x.png" />
          </OverlapGroup>
        </Group>
      </a>
  );
}

const Group = styled.div`
  height: 28px;
  align-self: flex-start;
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  align-items: left;
  min-width: 120px;
`;

const ContactUs = styled.div`
  ${OpensansBoldCerulean18px}
  min-height: 28px;
  min-width: 100px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
  margin-top: -4px;
`;

const OverlapGroup = styled.div`
  height: 16px;
  margin-left: 4px;
  margin-top: 2px;
  display: flex;
  padding: 5px;
  align-items: flex-start;
  min-width: 16px;
  border-radius: 16px;
  background: linear-gradient(180deg, #02a8ce 0%, #0086a4 100%);
`;

const Rectangle = styled.img`
  width: 5px;
  height: 5px;
  transform: rotate(-135deg);
`;

export default Group3;
