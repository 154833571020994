import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Button, Menu, MenuItem } from '@mui/material';

const useStyles = makeStyles(() => ({
    button: {
        color: 'white !important',
        padding: '0 !important',
        fontWeight: 'bold !important',
    },
}));

const DropdownWithLinks = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.button}
            >
                <h2>External Links</h2>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} sx={{ fontSize: '16px' }}>
                    <a href="https://myteleflex.com/teleflex-us/en/USD/">Teleflex US Site | Homepage</a>
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ fontSize: '16px' }}>
                    <a href="https://www.cookmedical.com/products/">Cook Medical</a>
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ fontSize: '16px' }}>
                    <a href="https://www.bd.com/en-us/products-andsolutions/products?tabindex=1&publishedAt=all-dates">BD</a>
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ fontSize: '16px' }}>
                    <a href="https://www.angiodynamics.com/products/">Angio Dynamics</a>
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ fontSize: '16px' }}>
                    <a href="https://tfxvascular.acto.com/auth/login">ACTO</a>
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ fontSize: '16px' }}>
                    <a href="https://teleflex.lightning.force.com/">Salesforce.com</a>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default DropdownWithLinks;
