import { css } from "styled-components";

export const OpensansNormalMineShaft14px = css`
  color: var(--mine-shaft);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;

export const OpensansNormalWhite18px = css`
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;

export const OpensansBoldMidGray24px = css`
  color: #00a9ce;
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const ArialmtNormalCerulean13px = css`
  color: var(--cerulean);
  font-family: var(--font-family-arialmt);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const OpensansLightWhite54px = css`
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xxl);
  font-weight: 300;
  font-style: normal;
`;

export const OpensansNormalMineShaft18px = css`
  color: var(--mine-shaft);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;

export const OpensansBoldDownriver18px = css`
  color: var(--downriver);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
`;

export const OpensansBoldCerulean18px = css`
  color: var(--cerulean);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
`;

export const OpensansBoldMidGray14px = css`
  color: #00a9ce;
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const OpensansBoldMadison14px = css`
  color: #00a9ce;
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const OpensansBoldMineShaft14px = css`
  color: var(--mine-shaft);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const Border1pxBonJour = css`
  border: 1px solid var(--bon-jour);
`;
