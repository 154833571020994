import React from "react";
import Group3 from "../Group3";
import styled from "styled-components";
import {
    OpensansNormalMineShaft14px,
    OpensansBoldDownriver18px,
    OpensansBoldMidGray24px,
    OpensansNormalMineShaft18px
} from "../../styledMixins";
import FrenchGaugeForm from "../FrenchGaugeForm/FrenchGaugeForm";
import Grid from "@mui/material/Grid";


function FrenchGauge(props) {
  const { instruct, className, frenchGaugeTitle } = props;

  return (
    <QuestionsSidebar1 className={`questions_sidebar ${className || ""}`}>
        <Grid container className="gauge-container" rowSpacing={1} columnSpacing={4} >
            <Grid item xs={12}>
                <FrenchGaugeTitle>{frenchGaugeTitle}</FrenchGaugeTitle>
                <FrenchGaugeSubtitle className="if-you-cant-find-a">{instruct}</FrenchGaugeSubtitle>
            </Grid>
            <Grid item xs={12}>
                <FrenchGaugeForm/>
            </Grid>
        </Grid>
    </QuestionsSidebar1>
  );
}

const QuestionsSidebar1 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 206px;

  &.questions_sidebar.questions_sidebar-2 {
    flex: 1;
    min-height: 100%;
    margin-left: unset;
  }
`;

const FrenchGaugeTitle = styled.div`
  ${OpensansBoldMidGray24px}
  width: 100%;
  min-height: 28px;
  margin-top: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
  @media only screen and (max-width: 600px) {
    font-size: 22px
  }
`;

const FrenchGaugeSubtitle = styled.p`
  ${OpensansNormalMineShaft18px}
  min-height: 28px;
  margin-top: 12px;
  letter-spacing: 0;
  line-height: 28px;
  
  @media only screen and (max-width: 600px) {
    font-size: 17px
  }
`;

export default FrenchGauge;
