import React, {Component, useEffect} from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { FetchData } from './components/FetchData';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'
import AdminPanel from './components/AdminPanel/index';
import LandingPage from "./components/LandingPage/index";

function App() {

    const [user, setUser] = React.useState(null);

    useEffect(() => {
        fetch('user', {
            method: 'GET'
        })
            .then(response => {
                if(response.status===401) {
                    document.location.href='/Identity/Account/Login';
                    return;
                }
                return response.json();
            })
            .then(userData => {
                if(userData)
                    setUser(userData);
            }).catch((ex) => {
                //if return 401 make user login
                console.error(ex);
            });
    }, []);//page load

      return (
          
      <Layout>
          {user && <>
              <AuthorizeRoute exact path="/" component={LandingPage} />
              <AuthorizeRoute path='/admin' component={AdminPanel} />
              <AuthorizeRoute path='/fetch-data' component={FetchData} />
          </>}

          <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  
}




const header1Data = {
    src: "/static/img/results-2-screen-shot-2022-05-18-at-113325-am-29373589-9725-47A5-B8DF-99E0ECE7DFDF.png",
};

const group321Data = {
    searchForAProduct: "Search for a Product SKU",
    startTypingOrSele: "Start typing or select your current product SKU(s) in the dropdown below.",
};

const inactiveDropdown21Data = {
    icon1: "/static/img/results-2-icon-DAB0DE24-0D35-4263-9236-38A06AFF919C@2x.png",
    icon2: "/static/img/landing-page-icon-6E0CE246-05F2-4D90-B3B0-9517DCD4255E@2x.png",
};

const group1Data = {
    exampleSku1: "Example SKU 1",
};

const productSelection1Data = {
    groupProps: group1Data,
};

const group2Data = {
    exampleSku1: "Example SKU 3",
};

const productSelection2Data = {
    className: "product_selection-1",
    groupProps: group2Data,
};

const group3Data = {
    exampleSku1: "Example SKU 4",
};

const productSelection3Data = {
    className: "product_selection-2",
    groupProps: group3Data,
};

const primaryCTA21Data = {
    children: "View Products",
};

const searchFormData = {
    inactiveDropdown2Props: inactiveDropdown21Data,
    productSelection1Props: productSelection1Data,
    productSelection2Props: productSelection2Data,
    productSelection3Props: productSelection3Data,
    primaryCTA2Props: primaryCTA21Data,
};

const questionsSidebar1Data = {
    questions: "Questions?",
    ifYouCanTFindA: "If you can't find a SKU number or have any other questions related to the SKU Cross Reference Tool, contact Customer Service.",
};

const frenchGaugeSidebar = {
    frenchGaugeTitle: "Gauge - French Conversion Tool",
    instruct: "Type a value into either input field and the corresponding conversion will be displayed in the other input field."
}

const resultsTableData = {
    teleflexProduct: "Teleflex Product",
    productNameLink1: <React.Fragment>Product Name + Link ›<br />Manufacturing #123456789</React.Fragment>,
    productNameLink2: <React.Fragment>Product Name + Link ›<br />Manufacturing #123456789</React.Fragment>,
    productNameLink3: <React.Fragment>Product Name + Link ›<br />Manufacturing #123456789</React.Fragment>,
    sku: "SKU",
    exampleSku1: "Example SKU 1",
    exampleSku3: "Example SKU 3",
    exampleSku4: "Example SKU 4",
    competitiveProduct: "Product Description",
    vendorNameManufact1: <React.Fragment>Vendor Name<br />Manufacturing #123456789</React.Fragment>,
    vendorNameManufact2: <React.Fragment>Vendor Name<br />Manufacturing #123456789</React.Fragment>,
    vendorNameManufact3: <React.Fragment>Vendor Name<br />Manufacturing #123456789</React.Fragment>,
};

const resultsOutputData = {
    yourResults: "Your Results",
    iconMail: "/static/img/results-2-icon-E6455AF0-D57C-4E01-8946-394D9F3864F3@2x.png",
    emailResults: "Email Results",
    resultsTableProps: resultsTableData,
};

const footer1Data = {
    src: "/static/img/results-2-screen-shot-2022-05-18-at-113432-am-E1F0CF04-1BD5-4405-AE50-34682C76EA09.png",
};

export const results2Data = {
    vascularAccessTool: "Vascular Access Tools / SKU Cross Reference Tool",
    gettyimages1321691592170667A: "/static/img/dropdown-gettyimages-1321691592-170667a-51A34ABE-7506-4E46-A450-4A19C6F218AD@2x.png",
    teleflexSkuCrossR: "Teleflex SKU Cross Reference Tool",
    enterOrSearchFor: "Enter or search for your current product’s SKU number to find the Teleflex product that best fits your needs.",
    headerProps: header1Data,
    group32Props: group321Data,
    searchFormProps: searchFormData,
    questionsSidebarProps: questionsSidebar1Data,
    frenchGaugeSidebar: frenchGaugeSidebar,
    resultsOutputProps: resultsOutputData,
    footerProps: footer1Data,
};

const sKUHeroData = {
    gettyimages1321691592170667A: "/static/img/dropdown-gettyimages-1321691592-170667a-51A34ABE-7506-4E46-A450-4A19C6F218AD@2x.png",
    teleflexSkuCrossR: "Teleflex SKU Cross Reference Tool",
    enterOrSearchFor: "Enter or search for your current product’s SKU number to find the Teleflex product that best fits your needs.",
};

const questionsSidebar4Data = {
    questions: "Questions?",
    ifYouCanTFindA: "If you can't find a SKU number or have any other questions related to the SKU Cross Reference Tool, contact Customer Service.",
    className: "questions_sidebar-2",
};

export const landingPageData = {
    screenShot20220518At113325Am: "/static/img/landing-page-screen-shot-2022-05-18-at-113325-am-D11255FC-D0C2-4048-BD9E-8BBAA0CBFB85.png",
    vascularAccessTool: "Vascular Access Tools / SKU Cross Reference Tool",
    searchForAProduct: "Search for a Product SKU",
    startTypingOrSele: "Start typing or select your current product SKU(s) in the dropdown below.",
    icon: "/static/img/landing-page-icon-6E0CE246-05F2-4D90-B3B0-9517DCD4255E@2x.png",
    currentSkuS: "Current SKU(s)*",
    footer: "/static/img/landing-page-screen-shot-2022-05-18-at-113432-am-CE8F42BA-8BBE-48F8-9899-F632722CE9A9.png",
    sKUHeroProps: sKUHeroData,
    questionsSidebarProps: questionsSidebar4Data,
    resultsOutputProps: resultsOutputData,
};

export default App;