import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import SKUHero from "./SKUHero";
import styled from "styled-components";
import PrimaryHeader from "./PrimaryHeader";
import PrimaryFooter from "./PrimaryFooter";

export class Layout extends Component {
  static displayName = Layout.name;
  
  render () {
      return (
      <TopDiv>
        <Container style={{flexGrow: 1}}>
            {this.props.children}
        </Container>
        <PrimaryFooter/>
      </TopDiv>
    );
  }
}

const TopDiv = styled.div`
    box-sizing: border-box;
    min-height: 100%;
    display: flex;
    flex-direction: column;
`;

// display: flex;
// flex-direction: column;
// align-items: flex-end;