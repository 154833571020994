import React from "react";
import { Link } from "react-router-dom";
import ResultsTable from "../ResultsTable";
import styled from "styled-components";
import { OpensansBoldMidGray24px } from "../../styledMixins";


function ResultsOutput(props) {
  const { yourResults, iconMail, emailResults, resultsTableProps, results, handleOpen } = props;

  console.log("Results (ResultsOutput):", results);
  
  return (
      <ResultsOutput1 id={"results"}>
        <FlexRow>
          <YourResults>{yourResults}</YourResults>
          <EmailCTA onClick={handleOpen}>
            <Group>
              <IconMail src={iconMail} />
              <EmailResults>{emailResults}</EmailResults>
            </Group>
          </EmailCTA>
        </FlexRow>
        <ResultsTable results={results} {...resultsTableProps} />
      </ResultsOutput1>
  );
}

const ResultsOutput1 = styled.div`
  max-width: 1246px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  @media only screen and (max-width: 600px) {
    padding-left: 15px
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 1246px;
  width: 100%;
`;

const YourResults = styled.div`
  ${OpensansBoldMidGray24px}
  min-height: 33px;
  margin-top: 1px;
  min-width: 151px;
  font-weight: 700;
  letter-spacing: 0;
`;

const EmailCTA = styled.div`
  height: 40px;
  margin-left: auto;
  display: flex;
  padding: 9px 20px;
  align-items: flex-start;
  min-width: 149px;
  background-color: var(--madison-2);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  }
`;

const Group = styled.div`
  height: 21px;
  display: flex;
  align-items: center;
  min-width: 109px;
`;

const IconMail = styled.img`
  width: 14px;
  height: 11px;
`;

const EmailResults = styled.div`
  min-height: 21px;
  margin-left: 8px;
  min-width: 89px;
  font-family: var(--font-family-open_sans);
  color: var(--white);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  line-height: 21px;
  white-space: nowrap;
`;

export default ResultsOutput;
