import React from "react";
import styled from "styled-components";
import '../tfxStyles.css';
import '../bootstrap.min.css';
import '../font-awesome.css';
import DropdownWithLinks from "../DropdownWithLinks";

function PrimaryFooter(props) {
  const { src } = props;

  return (
      <Footer>
          <div className="footer-v3">

              {
                  window.location.pathname !== '/admin' &&
                  <div className="footer">
                      <div className="container">
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="thumb-headline">
                                      <h2><a href="https://www.teleflex.com/usa/en/legal/internet-privacy-policy/index.html">PRIVACY POLICY</a></h2>
                                  </div>
                                  <div className="thumb-headline">
                                      <h2><a href="https://www.teleflex.com/usa/en/contact/north-america/index.html">CONTACT</a></h2>
                                  </div>
                                  <div className="thumb-headline">
                                      <h2><a href="https://www.teleflex.com/usa/en/legal/trademarks/index.html">TRADEMARKS</a></h2>
                                  </div>
                                  <div className="thumb-headline">
                                      <h2><a href="https://www.teleflex.com/usa/en/">TELEFLEX.COM</a></h2>
                                  </div>
                                  <div className="thumb-headline thumb-links">
                                    <DropdownWithLinks/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              }
              
              
              <div className="copyright">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-12">
                              <p>
                              </p>
                              <p id="copyright">© 2023 Teleflex Incorporated. All Rights Reserved. MC-008719</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </Footer>
  );
}

const Footer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 100px;
`;
export default PrimaryFooter;
