import React from "react";
import styled from "styled-components";
import { OpensansLightWhite54px, OpensansNormalWhite18px } from "../../styledMixins";


function SKUHero(props) {
  const { gettyimages1321691592170667A, teleflexSkuCrossR, enterOrSearchFor } = props;

  return (
    <SKUHero1>
      <OverlapGroup id={"overlay-grp"}>
        <Gettyimages1321691592170667a src={gettyimages1321691592170667A} />
        <Group2>
          <TeleflexSKUCrossR>{teleflexSkuCrossR}</TeleflexSKUCrossR>
          <EnterOrSearchFor>{enterOrSearchFor}</EnterOrSearchFor>
        </Group2>
      </OverlapGroup>
    </SKUHero1>
  );
}

const SKUHero1 = styled.div`
  margin-top: 19px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 1246px;
  background: linear-gradient(180deg, #02a8ce 0%, #0086a4 100%);
`;

const OverlapGroup = styled.div`
  width: 100%;
  min-height: 380px;
  position: relative;
`;

const Gettyimages1321691592170667a = styled.img`
  position: absolute;
  width: 820px;
  height: 380px;
  top: 0;
  left: 290px;
`;

const Group2 = styled.div`
  max-width: 614px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 212px;
  padding: 75px;
`;

const TeleflexSKUCrossR = styled.div`
  ${OpensansLightWhite54px}
  width: 585px;
  min-height: 136px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 68px;
`;

const EnterOrSearchFor = styled.div`
  ${OpensansNormalWhite18px}
  max-width: 614px;
  min-height: 56px;
  margin-top: 20px;
  letter-spacing: 0;
  line-height: 28px;
`;

export default SKUHero;
