import React, {useEffect, useRef, useState} from "react";
import "./AdminPanel.css";
import Grid from '@mui/material/Grid';
import {Button, Typography} from "@mui/material";
import styled from "styled-components";
import {
    OpensansBoldDownriver18px,
    OpensansBoldMidGray24px,
    OpensansNormalMineShaft14px,
    OpensansNormalWhite18px
} from "../../styledMixins";
import LoopIcon from '@mui/icons-material/Loop';
import authService from "../api-authorization/AuthorizeService";
import PrimaryHeader from "../PrimaryHeader";

function AdminPanel() {
    const fileRef = useRef();

    const [loading, setLoading] = useState(false);

    const [isAdminUser, setIsAdminUser] = React.useState(null);
    
    useEffect(() => {
        fetch('adminCheck', {
            method: 'GET'
        })
            .then(response => {
                if(response.status===401) {
                    document.location.href='/Identity/Account/Login';
                    return;
                }
                return response.json();
            })
            .then(isAdminUser => {
                setIsAdminUser(isAdminUser);
                if(!isAdminUser)
                    document.location.href="/";
            }).catch((ex) => {
            //if return 401 make user login
            console.error(ex);
        });
    }, []);//page load

    const importData = async (e) => {

        setLoading(true);
        const files = event.target.files
        const formData = new FormData()
        formData.append('file', files[0])
        const token = await authService.getAccessToken();

        fetch('SkuEntry', {
            method: 'POST',
            body: formData,
            headers: {
                headers: !token 
                    ? {} 
                    : {'Authorization': `Bearer ${token}`}}
        }).then(response => {
            console.log("importData response:", response);
            setLoading(false);
        }).catch((ex) => {
            console.log(ex);
            setLoading(false);
        });

    }
    
    const download = async (e) => {
        event.preventDefault();
        const form = event.target;
        let queryParam = (form.elements);

        let url = window.location.hostname+'/export';
        console.log(window.location.hostname);
        
        fetch(url,{
            method: 'GET',
        }).then((response)=>response.blob())
            .then((blob)=>{
                const url2 = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href=url2;
                link.setAttribute('download','export.csv',);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
            
         
    }

    return (
        <Grid container spacing={2}>
            {isAdminUser && 
                <>
                    <Grid item xs={12} className="title-container">
                        <PrimaryHeader/>
                        <AdminTitle>Sales Enablement Cross Reference Tool Admin Console</AdminTitle>
                    </Grid>
                    <Grid item xs={12}>
                    <div className="export-body">
                        <ImportInstruct>This tool's purpose is to import a spreadsheet containing a list of competitor SKU and their relevant TFX SKU which are to be compared against.</ImportInstruct>
                        <ImportInstruct>You may click <a href={`/instruction`} target="_blank"> here</a> to download the instructions in a pdf form with sample images.</ImportInstruct>
                        <ImportInstruct>The template is linked below for a fresh sheet, alternatively you can click the Download Current Data button to get a copy of the data currently in the system and add onto it.
                            <b> Note that upon uploading a new spreasheet via the import button all existing entries will be cleared and replaced with the contents of the uploaded file.</b></ImportInstruct>
                        <ImportInstruct>The first 3 columns: Vendor Name, Competitor SKU/Manufacturing Number, and Manufacturing Description are all mandatory for each entrty.
                        After these are filled out any number of additional Teleflex Cross references can be added. All that needs to be done is the SKU is entered followed by the description for that SKU.
                        In the template 3 Argon Medical entries are already entered in to demonstrate how to fill out the sheet, each with a different amount of Teleflex Cross References.</ImportInstruct>
                        <ImportInstruct>Click <a href={`/template`} target="_blank"> here</a> to download the template and begin editing.</ImportInstruct>
    
                        {loading
                            ?
                            <ImportButton disabled>
                                <ImportText>
                                    <LoopIcon sx={{marginBottom: "-6px"}}/>
                                    Import
                                </ImportText>
                            </ImportButton>
                            :
                            <div>
                                <ImportButton
                                    onClick={() => fileRef.current.click()}
                                >
                                    <ImportText>Import</ImportText>
                                </ImportButton>
                                <input
                                    ref={fileRef}
                                    onChange={importData}
                                    multiple={false}
                                    type="file"
                                    hidden
                                />
                            </div>
                        }
                    <div>
                        {loading
                            ?
                            <ImportButton disabled>
                                <ImportText>
                                    <LoopIcon sx={{marginBottom: "-6px"}}/>
                                    Download Current Data
                                </ImportText>
                            </ImportButton>
                            :
                            <div>
                                <ImportButton>
                                <a
                                   href={'/export'}
                                >
                                    <ImportText>Download Current Data</ImportText>
                                </a>
                                    </ImportButton>
                            </div>
                        }
                    </div>
                    </div>
                    </Grid>
                </>
            }
        </Grid>
    );

}

const AdminTitle = styled.div`
  ${OpensansBoldMidGray24px}
  min-height: 28px;
  font-weight: 700;
  letter-spacing: 0;
  padding-left: 50px;
  padding-top: 25px;
  
  @media only screen and (max-width: 600px) {
    font-size: 22px
  }
`;

const ImportTitle = styled.div`
  ${OpensansBoldDownriver18px}
  margin-bottom: 10px;
  width: 100%;
  min-height: 28px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const ImportInstruct = styled.p`
  ${OpensansNormalMineShaft14px}
  width: 100%;
  letter-spacing: 0;
  line-height: 21px;
  font-size: 16px;
`;

const ImportText = styled.div`
  ${OpensansNormalWhite18px}
  width: fit-content;  
  min-height: 28px;
  min-width: 90px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
  text-align: center;
`;

const ImportButton = styled.button`
  border: none;
  width: fit-content;  
  height: 48px;
  margin-top: 16px;
  display: flex;
  padding: 0 28px;
  justify-content: flex-end;
  align-items: center;
  min-width: 90px;
  background: linear-gradient(180deg, #02a8ce 0%, #0086a4 100%);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-align: center;
  
  &:disabled {
    opacity: 0.5;
    color: lightgray;
    pointer-events: none;
    cursor: default;
  }

  &:hover {
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  }
`;

export default AdminPanel;