import React from "react";
import Group3 from "../Group3";
import styled from "styled-components";
import { OpensansNormalMineShaft14px, OpensansBoldDownriver18px } from "../../styledMixins";


function QuestionsSidebar(props) {
  const { questions, ifYouCanTFindA, className } = props;

  return (
    <QuestionsSidebar1 className={`questions_sidebar ${className || ""}`}>
      <Rectangle className="rectangle-1"></Rectangle>
      <Questions className="questions">{questions}</Questions>
      <IfYouCantFindA className="if-you-cant-find-a">{ifYouCanTFindA}</IfYouCantFindA>
      <Group3 />
    </QuestionsSidebar1>
  );
}

const QuestionsSidebar1 = styled.div`
  width: 280px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 206px;
  background-color: #f7f7f7;

  &.questions_sidebar.questions_sidebar-2 {
    flex: 1;
    width: 307px;
    min-height: 100%;
    margin-left: unset;
  }
  
  
  
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Rectangle = styled.div`
  width: 100%;
  height: 2px;
  background-color: #00205a;
`;

const Questions = styled.div`
  ${OpensansBoldDownriver18px}
  width: 100%;
  padding-left: 20px;
  min-height: 28px;
  margin-top: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
`;

const IfYouCantFindA = styled.p`
  ${OpensansNormalMineShaft14px}
  width: 100%;
  padding: 0 20px;
  min-height: 84px;
  margin-top: 7px;
  letter-spacing: 0;
  line-height: 21px;
  font-size: 16px;
`;


export default QuestionsSidebar;
